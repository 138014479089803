const OVERVIEW_KEY = 'overview_'

const isSectionOverview = id => id === 'first-article'

const isActive = (getCurrentSection, getCurrentSectionItemId, section, actualArticleId) => {
  const { items, id: sectionId } = getCurrentSection
  const currentArticle = getCurrentSectionItemId
  if (section && sectionId === section.id) {
    const indexCurrentArticle = items.findIndex(({ id }) => id === currentArticle)
    if (currentArticle === actualArticleId) {
      if (indexCurrentArticle === 1 && !items[0].opened) {
        return false
      }
      return true
    } else if (indexCurrentArticle === 1 && isSectionOverview(actualArticleId)) {
      return true
    } else if (isSectionOverview(actualArticleId)) {
      return items[1]?.opened || false
    }
  } else if (section) {
    const { items: itemsSection } = section
    if (isSectionOverview(actualArticleId)) {
      return itemsSection[0]?.opened || false
    }
  }
  return false
}

const isCompleted = (section, item) => {
  if (!isSectionOverview(item.id)) return item.opened
  if (!section) return false

  const { items } = section

  const [first, second] = items
  if (isSectionOverview(first.id)) return second.opened

  return first.opened
}

const nextLinkItem = (getCurrentSection, getCurrentSectionItem) => {
  const { items, id: sectionId } = getCurrentSection
  const currentArticle = getCurrentSectionItem
  const indexCurrentArticle = items.findIndex(({ id }) => id === currentArticle.id)

  if (isSectionOverview(currentArticle.id)) {
    return {
      name: 'ArticleView',
      params: {
        id: `${OVERVIEW_KEY}${sectionId}`,
        section: 'overview',
      },
    }
  } else if (indexCurrentArticle === 1 && !items[0].opened) {
    return {
      name: 'ArticleView',
      params: {
        id: `${OVERVIEW_KEY}${sectionId}`,
        section: 'overview',
      },
    }
  }

  return {
    name: 'ArticleView', params: { id: getCurrentSectionItem?.id },
  }
}

export default {
  isSectionOverview,
  isActive,
  isCompleted,
  nextLinkItem,
  OVERVIEW_KEY,
}
