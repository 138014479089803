<template>
  <modular-tile
    class="widget-start"
    :imagePosition="'side'"
  >
    <template #default>
      <h2 class="widget-start__title">
        {{ widget[`title_${stage}`] }}
      </h2>
      <div
        v-if="isStage('completed')"
        class="widget-start-complete"
      >
        <img
          class="widget-start-complete__img"
          alt=""
          role="presentation"
          :src="completeProgrammeImgUrl"
        >
        <span
          class="widget-start-complete__text"
        >
        {{ widget[`congratulations_text_${stage}`] }}
        </span>
      </div>
      <p class="nhsuk-u-margin-bottom-2">
        You have completed <b>{{ progressPercent }}%</b> of the course
      </p>
      <p
        class="widget-start__description"
        :class="{
          'widget-start__description--is-bolded' : isStage('inProgress')
        }"
      >
        {{ widget[`description_${stage}`] }}
      </p>
      <div
        v-if="isStage('inProgress') || isStage('start')"
        :class="{
          'container-spinner': loading
        }"
      >
        <SpinnerDots
          v-if="loading"
        />
        <next-article-tile
          v-else
          :imageUrl="nextArticleImgUrl"
          :title="nextArticleTitle"
          v-bind="{ link }"
          @shiftTab="onShiftTab($event, 'nextArticleTile')"
        />
      </div>
    </template>

    <template #imageReplacement>
      <circular-chart
        v-if="progressPercent || progressPercent === 0"
        :value="progressPercent"
      >
        <template #content>
          <span
            class="circular-chart__percentage"
            aria-hidden="true"
          >
            {{ progressPercent }}%
          </span>
          <screen-readers-only>
            {{ progressPercent }}%
            {{ $t(`${$options.slug}.journey-complete`) }}
          </screen-readers-only>
        </template>
      </circular-chart>
    </template>

    <template #action>
      <div
        @keydown.exact.tab="$emit('tabOut', $event)"
        @keydown.shift.tab="onShiftTab($event, 'action')"
      >
        <router-link
          v-if="isStage('completed')"
          class="anchor"
          data-testid="widget-start-link"
          :to="link"
        >
          {{ widget[`cta_button_text_${stage}`] }}
        </router-link>
        <router-link
          v-if="!isStage('completed') && ((isStage('inProgress') || isStage('start')) && !loading)"
          class="continue-course-anchor"
          data-testid="widget-start-link"
          :to="link"
        >
          <button
            class="continue-course w-100"
            :dataTestid="'continue-course'">
            {{ widget[`cta_button_text_${stage}`] }}
            <inline-svg
              class="icon"
              role="presentation"
              src="icons/icon-cheveron-right.svg"
            />
          </button>
        </router-link>
      </div>
    </template>
  </modular-tile>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import CircularChart from '@/components/charts/circular-chart/CircularChart'
  import ScreenReadersOnly from '@/components/screen-readers-only/ScreenReadersOnly'
  import { SpinnerDots } from '@/components/spinner'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'
  import NextArticleTile from '@/components/tiles/next-article-tile/NextArticleTile'
  import sectionOverview from '@/store/helpers/sectionOverview'

  const STAGES = {
    start: 'no_progress',
    inProgress: 'in_progress',
    completed: 'done_progress',
  }

  const STAGES_BY_PROGRESS = {
    default: STAGES.inProgress,
    0: STAGES.start,
    100: STAGES.completed,
  }

  export default {
    components: {
      CircularChart,
      ModularTile,
      NextArticleTile,
      ScreenReadersOnly,
      SpinnerDots,
    },

    props: {
      widget: {
        type: Object,
        required: true,
      },
    },

    methods: {
      isStage(lvl) {
        return this.stage === STAGES[lvl]
      },
      onShiftTab(e, element) {
        if (element === 'action' && this.isStage('inProgress')) return

        this.$emit('shiftTabOut', e)
      },
    },

    computed: {
      ...mapGetters('program', [
        'getCurrentSectionItem',
        'isJourneyUpToDate',
        'getCurrentSection',
        'enableModules',
      ]),
      ...mapState('program', [
        'progress',
      ]),
      stage() {
        return STAGES_BY_PROGRESS[this.progressPercent] ?? STAGES_BY_PROGRESS.default
      },
      link() {
        if (this.isStage('completed')) return { name: 'Learn' }

        if (this.enableModules) {
          return sectionOverview
            .nextLinkItem(this.getCurrentSection, this.getCurrentSectionItem)
        }

        return {
          name: 'ArticleView', params: { id: this.item.id },
        }
      },
      nextArticleTitle() {
        return this.item.title
          ?? this.item.display_name
      },
      nextArticleImgUrl() {
        return this.item.icon?.url
          // eslint-disable-next-line global-require
          ?? require('@/assets/img/modular-tile/top-opd-630.png')
      },
      completeProgrammeImgUrl() {
        // eslint-disable-next-line global-require
        return require('@/assets/img/modular-tile/complete-programme-gratulations.svg')
      },
      item() {
        return this.getCurrentSectionItem
      },
      progressPercent() {
        return this.progress?.progress
      },
      loading() {
        return !this.isJourneyUpToDate
      },
    },

    slug: 'component.tiles.circle-progress-tile',
  }
</script>

<style lang="scss">
  .widget-start {
    .continue-course-anchor {
      text-decoration: none;
    }
    .w-100 {
      min-width: 100%;
      display: block;
      text-align: start;
    }
    .container-spinner {
      margin: auto;
    }
    &__title {
      margin: 0;
      margin-bottom: 16px;
      font-size: 2.1rem;
      font-weight: 600;
    }
    &__description {
      margin: 0 0 1rem 0;
      &--is-bolded {
        font-size: 1.4rem;
        font-weight: 600;
      }
    }
    &-complete {
      margin: 0 0 2rem 0;
      position: relative;
      &__text {
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        color: color(dark-primary);
        font-size: 2.4rem;
        font-weight: 600;
        line-height: 1;
        text-align: center;
        transform: translateY(-50%);
        @include max-sm {
          font-size: 1.6rem;
        }
        @include max-md {
          font-size: 3rem;
        }
        @include max-lg {
          font-size: 1.6rem;
        }
      }
    }
    .circular-chart {
      &__percentage {
        padding: 0;
        font-size: 4rem;
        line-height: 1.6rem;
      }
      &__inner-text {
        font-size: 1.2rem;
        @include min-md {
          font-size: 1.4rem;
        }
      }
    }
    & .modular-tile {
      &__header-row--no-header-text {
        display: none;
      }
      &__main {
        padding: 2.2rem;
      }
      &__media {
        min-width: 20rem;
        max-width: 20rem;
        border-radius: 1rem 0 0 1rem;
        @media all and (max-width: 440px) {
          display: none;
        }
      }
      &__content {
        flex-direction: column;
        justify-content: center;
        margin: 0 0 1rem;
      }
    }
  }
</style>
