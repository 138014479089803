import { isArray, mergeWith } from 'lodash'
import Vue from 'vue'

import rootGetters from '@/store/helpers/rootGetters'

import initialState from './state'

function isCompletedProgram(program) {
  return program.progress.progress === 100
}

function addFirstArticleToSections(stateSections) {
  const sections = stateSections.map(section => {
    const { items } = section
    const [firstItem] = items
    const isFirstItemOpened = firstItem.opened

    const firstArticle = {
      id: 'first-article',
      name: 'Section overview',
      title: 'Section overview',
      opened: isFirstItemOpened,
      type: 'first-article',
      sectionId: section.id,
    }

    section.items = [firstArticle, ...items]
    return section
  })

  return sections
}

function generateModulesWithSections(program) {
  const modulesWithSections = new Map()
  program.modules.forEach(({ id }) => {
    const sections = program.journey.sections.filter(({ module }) => module === id)
    if (sections.length > 0) {
      modulesWithSections.set(id, sections)
    }
  })

  return modulesWithSections
}

function generateModulesSectionsWithOverview(program, modulesOverview) {
  const modulesWithSections = new Map()
  const lastSection = program.journey.sections[program.journey.sections.length - 1]
  const { module: lastSectionModule, id: lastSectionId } = lastSection
  const optionalProfileDataMap = rootGetters('optionalProfileData/getProfileDataMap')
  // program.modules was sorted before (setJourney method)
  const currentModuleIndex = program.modules.findIndex(({ id }) => id === lastSectionModule)

  program.modules.forEach(({ id }, index) => {
    const overviewModule = modulesOverview[id]
    const { sections: sectionsTemp } = overviewModule

    const overviewSections = sectionsTemp
      .sort(({ order: aOrder }, { order: bOrder }) => aOrder - bOrder)
    const sectionsJourneyByModule = program.journey.sections.filter(({ module }) => module === id)

    let arraySectionsWithOverview = []
    if (overviewSections.length === sectionsJourneyByModule.length) {
      arraySectionsWithOverview = sectionsJourneyByModule
    } else {
      let sections = overviewSections
      if (id === lastSectionModule) {
        const lastSectionIndex = overviewSections.findIndex(({ id: sId }) => sId === lastSectionId)
        sections = overviewSections.slice(lastSectionIndex)
      }
      const sectionsIdJourneyByModule = sectionsJourneyByModule.map(section => section.id)

      let missedJourneySections = []
      // If the module index is less than the current module we ignore the missed sections
      if (index >= currentModuleIndex) {
        missedJourneySections = sections
        .filter(section => !sectionsIdJourneyByModule.includes(section.id))
        .map(section => ({
          ...section,
          items: section.items
              .map(item => {
                if (item.type === 'profile-data') {
                  const profileData = optionalProfileDataMap?.get(item.subject_id)
                  return { ...item, display_name: profileData || item.title || item.system_name || '' }
                }
                return { ...item, display_name: item.title || item.system_name || '' }
              }),
        }))
      }

      arraySectionsWithOverview = [...sectionsJourneyByModule, ...missedJourneySections]
    }

    arraySectionsWithOverview = addFirstArticleToSections(arraySectionsWithOverview)

    if (arraySectionsWithOverview.length > 0) {
      modulesWithSections.set(id, arraySectionsWithOverview)
    }
  })

  return modulesWithSections
}

export default {
  setProgram(state, program) {
    state.program = program
    state.isProgramFetched = true
    state.isJourneyUpToDate = program.journey !== undefined
  },
  setJourney(state, program) {
    if (state.program) {
      state.program = {
        ...state.program,
        ...program,
        modules: program.modules.sort(({ order: aOrder }, { order: bOrder }) => aOrder - bOrder),
      }
      state.isJourneyUpToDate = true
      if (rootGetters('program/enableModules') && program.modules.length > 0) {
        if (isCompletedProgram(program)) {
          state.program.journey.sections = addFirstArticleToSections(state.program.journey.sections)
          state.modulesWithSections = generateModulesWithSections(state.program)
        }
      }
    }
  },
  setJourneyOverview(state, modulesOverview) {
    const { program } = state
    if (!rootGetters('program/enableModules') || program.modules.length === 0) {
      return
    }

    if (isCompletedProgram(program)) {
      return
    }

    state.modulesOverview = modulesOverview
    state.modulesWithSections = generateModulesSectionsWithOverview(program, modulesOverview)
  },
  setInitialCurrentProgramId(state, id) {
    state.initialCurrentProgramId = id
  },
  setProgress(state, progress) {
    const { updated_at: stateProgressDate } = state.progress || {}
    const { updated_at: incomingProgressDate } = progress || {}

    let progressToSet

    if (!stateProgressDate) {
      progressToSet = progress
    } else {
      progressToSet = stateProgressDate < incomingProgressDate
        ? progress
        : state.progress
    }

    state.progress = {
      progress: parseInt(progressToSet.progress ?? 0, 10),
      updated_at: progressToSet.updated_at,
    }
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
  mergeProgram(state, program) {
    mergeWith(state.program, program, (objValue, srcValue) => {
      if (isArray(objValue)) {
        objValue.forEach((item, idx, arr) => {
          const duplicateItemIdx = srcValue.findIndex(srcItem => srcItem.key === item.key)

          if (duplicateItemIdx < 0) return

          arr[idx] = srcValue[duplicateItemIdx]
          srcValue.splice(duplicateItemIdx, 1)
        })

        return objValue.concat(srcValue)
      }
    })

    if (rootGetters('program/enableModules')
      && state.program.modules.length > 0
      && program.has_next_section) {
      state.modulesWithSections = generateModulesSectionsWithOverview(
        state.program, state.modulesOverview, state,
      )
    }
  },
  flagSectionItemOpened(state, { sectionItem, flag }) {
    sectionItem.opened = flag
  },
  flagProgramUpToDate(state, flag) {
    state.isJourneyUpToDate = flag
  },
}
