<template>

    <div class="article">
    <article
        class="article-text"
        data-testid="base-article-text"
    >
        <modular-tile
        class="modular-tile_blue"
        :headerText="`${indexModuleSection} ${section.display_name}`"
        :headerLevel="1"
        >
        <div class="modular-tile-content">
            <p class="length-articles">
            {{ articlesToShow.length }} Articles
            </p>

            <h3 class="section-includes nhsuk-u-margin-bottom-3">
            This section includes:
            </h3>

            <ul>
            <li
                class="nhsuk-u-margin-bottom-3"
                v-for="(item, i) in articlesToShow"
                :key="i">
                {{ item.title || item.display_name }}
            </li>
            </ul>

            <button
              class="continue-course-button"
              :dataTestid="'continue-course'"
              @click="startSection">
              Start this section
              <inline-svg
                  class="icon"
                  role="presentation"
                  src="icons/icon-cheveron-right.svg"
              />
            </button>

            <div class="nhsuk-u-margin-bottom-3 nhsuk-u-margin-top-3">
            <router-link
            aria-label="Take a break and come back later"
            class="take-a-break_text"
            data-testid="take-a-break"
            :to="{ name: 'Home' }"
            >
            Take a break and come back later
            </router-link>
            </div>
        </div>
        </modular-tile>
    </article>
    </div>

</template>

<script>
  import { mapGetters, mapMutations, mapState } from 'vuex'

  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  export default {
    metaInfo() {
      return {
        title: 'Section overview',
      }
    },

    components: {
      ModularTile,
    },

    props: {
      sectionId: {
        type: [String, Number],
        required: true,
      },
    },

    destroyed() {
      this.resetBreadcrumbs()
    },

    watch: {
      program: {
        handler() {
          if (this.program.journey) {
              this.loadSection()
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        section: null,
        indexModuleSection: '',
      }
    },

    methods: {
      ...mapMutations('route', [
        'setBreadcrumbTitle',
        'resetBreadcrumbs',
      ]),
      loadSection() {
        this.setBreadcrumbTitle('Section overview')
        this.section = this.getSection(this.sectionId)
        const sectionModule = this.program.modules.find(({ id }) => id === this.section.module)
        const sectionsByModuleId = this.getSectionsByModule(sectionModule.id) || []

        const sectionIndex = sectionsByModuleId
            .findIndex(({ id }) => id === this.sectionId) + 1
        this.indexModuleSection = `${sectionModule.order}.${sectionIndex}`
      },
      startSection() {
        this.$router.push(this.link)
      },
    },

    computed: {
      ...mapGetters('program', [
        'getSection',
        'getCurrentSectionItem',
        'getSectionsByModule',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      ...mapState('program', [
        'program',
      ]),
      articlesToShow() {
        return this.section.items.filter(({ id }) => id !== 'first-article')
      },
      loading() {
        const loadings = [
          'program/fetchProgram',
        ]

        return loadings
      },
      isLoadingJourney() {
        return this.getLoadingStatesForActions([
          'bootstrap/journey',
        ])
      },
      link() {
        const [, secondArticle] = this.section.items
        return {
          name: 'ArticleView', params: { id: secondArticle?.id },
        }
      },
    },
  }
  </script>

  <style lang="scss">
    .article-view {
      &__wrapper {
        @include view-wrapper(63rem);
      }

      .article {
        width: 100%;
        position: relative;
        padding: 0 0 4rem;

        .modular-tile.modular-tile_blue {
          background-color: #19407D;
          color: color(_white);

          .modular-tile-content {
            width: 100%;

            ul {
              padding: 0rem;
            }
            p,li,a {
              font-family: $averta !important;
              font-size: 1.5rem !important;
              line-height: 1.5;
            }
            h3 {
              font-size: 1.8rem !important;
            }

            .section-includes {
              font-weight: bold;
            }

            .continue-course-button {
              margin: 0;
              height: auto;
              margin-top: 1rem;
              border-radius: 4px;
              background-color: color(_white);
              color: #19407D;
              font-size: 1.8rem;
              font-weight: 700;
              padding: 0.5rem 1.5rem;
              display: flex;
              align-items: center;
              justify-content: space-between;
              &:focus {
                box-shadow: 0 0 0 5px var(--color-accent);
              }
              &::before {
                background-color: color(_white);
                box-shadow: none;
              }
              .icon {
                fill: #19407D;
                width: 3rem;
                svg {
                  width: 4rem;
                  height: 4rem;
                }
              }
            }

            .take-a-break_text {
              color: color(_white);
              text-decoration: underline;
            }
          }
        }

      }

      .length-articles {
        width: 100%;
        padding: 1rem 0rem;
        margin-bottom: 1rem;
        border-top: solid 2px color(_white);
        border-bottom: solid 2px color(_white);
      }
    }
  </style>
