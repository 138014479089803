<template>
  <div>
    <p class="nhsuk-u-font-size-24 nhsuk-u-margin-bottom-2 semibold">
      Estimated time: {{ section.estimated_time }}
    </p>
    <p class="nhsuk-u-font-size-24 nhsuk-u-margin-bottom-2">
      {{ section.description && section.description[currentUserLanguage]
        ? section.description[currentUserLanguage] : '' }}
    </p>
    <details
      class="sections-module-accordion"
      v-bind="{open: isExpanded}"
      @toggle="toggle">
      <summary class="sections-module-summary">
        <span class="nhsuk-u-font-size-24">
          View the articles
        </span>
      </summary>
      <ul class="sections-module-text">
        <li
          v-for="(item, k) in section.items"
          :key="k"
          :class="{
              'journey-article--is-completed': isItemCompleted(item),
          }">
          <journey-module-article
            :item="item"
            :sectionId="section.id" />
        </li>
      </ul>
    </details>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import sectionOverview from '@/store/helpers/sectionOverview'

  import JourneyModuleArticle from './JourneyModuleArticle'

  export default {
    components: {
      JourneyModuleArticle,
    },

    props: {
      section: {
        type: Object,
        required: true,
      },
    },
    mounted() {
      this.isExpanded = !this.isJourneyCompleted && this.getCurrentSection.id === this.section.id
    },
    data() {
      return {
        isExpanded: false,
        sectionsByModuleId: [],
      }
    },
    methods: {
      toggle({ target }) {
        this.isExpanded = target.open
      },
      isItemCompleted(item) {
        return sectionOverview.isCompleted(
          this.getSection(this.section.id),
          item,
        )
      },
    },
    computed: {
      ...mapGetters('program', [
        'getSectionsByModule',
        'getCurrentSection',
        'getCurrentSectionItemId',
        'getSection',
      ]),
      ...mapState('languages', [
        'currentUserLanguage',
      ]),
      ...mapState('program', [
        'progress',
      ]),
      isJourneyCompleted() {
        return this.progress?.progress === 100
      },
    },
  }
</script>

<style lang="scss">
  .sections-module-accordion {
    &[open]>.sections-module-summary::before {
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: rgba(0, 0, 0, 0);
      clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
      border-width: 12.124px 7px 0 7px;
      border-top-color: inherit;
    }
    .sections-module-summary {
      color: #005eb8;
      cursor: pointer;
      display: inline-block;
      padding-left: 24px;
      position: relative;

      span {
        text-decoration: underline;
      }

      &::before {
        bottom: 0;
        content: "";
        left: 0;
        margin: auto;
        position: absolute;
        top: 0;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0);
        clip-path: polygon(0% 0%, 100% 50%, 0% 100%);
        border-width: 7px 0 7px 12.124px;
        border-left-color: inherit;
      }
    }

    .sections-module-text {
      border-left: 4px solid #d8dde0;
      margin-top: 8px;
      padding: 16px;
      padding-left: 25px;
    }
  }
</style>
