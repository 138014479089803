<script>
  import { has } from 'lodash'

  import { BaseButton } from '@/components/base'
  import { Heading } from '@/components/heading'
  import ProgressIndicator from '@/components/progress-indicator/ProgressIndicator'
  import ModularTileImage from '@/components/tiles/modular-tile/ModularTileImage'
  import Tooltip from '@/components/tooltip/Tooltip'
  import { hasSlot, partitionAttrs, requireImage } from '@/helpers'

  const slug = 'component.tiles.modular-tile'

  const MODIFIERS = [
    'align-right',
    'centered',
    'dark',
    'is-empty',
  ]

  export default {
    functional: true,

    props: {
      backgroundImage: {
        type: String,
        default: '',
      },
      closeAction: {
        type: Function,
        default: null,
      },
      disableHeaderStyling: {
        type: Boolean,
        default: false,
      },
      hasCloseButton: {
        type: Boolean,
        default: true,
      },
      headerText: {
        type: String,
        default: '',
      },
      headerLevel: {
        type: Number,
        default: 3,
      },
      height: {
        type: [Number, String],
        default: 'auto',
      },
      image: {
        type: String,
        default: '',
      },
      imagePosition: {
        type: String,
        default: '',
      },
      tooltipText: {
        type: String,
        default: '',
      },
      width: {
        type: [Number, String],
        default: '100%',
      },
      showLinkToTitle: {
        type: Boolean,
        default: false,
      },
      isSectionSummary: {
        type: Boolean,
        default: false,
      },
      progress: {
        type: Number,
        default: 0,
      },
      program: {
        type: String,
        default: '',
      },
    },

    render(h, { data, parent, props, slots }) {
      const { attrs = {}, staticClass, ...newData } = data
      const { 'data-testid': dataTestid = '', ..._attrs } = attrs
      const blockClass = 'modular-tile'
      const styles = () => {
        const customDimensions = {
          width: 'auto',
          height: 'auto',
        }

        if (props.width !== 'auto') {
          customDimensions.width = `${props.width}px`
          customDimensions.display = 'inline-flex'
        }
        if (props.height !== 'auto') {
          customDimensions.height = props.height
        }

        return customDimensions
      }
      const getTestId = testIdSlug => [blockClass, testIdSlug]
        .filter(Boolean)
        .join('-')

      const { classes, newAttrs } = partitionAttrs({
        attrs: _attrs,
        baseClass: blockClass,
        modifiers: MODIFIERS,
      })

      const additionalClasses = {
        [`${blockClass}--icon`]: hasSlot(slots, 'icon'),
        [`${blockClass}--has-action`]: hasSlot(slots, 'action') && !props.showLinkToTitle,
        [`${blockClass}--has-${props.imagePosition}-image`]: props.imagePosition,
      }

      Object.entries(additionalClasses).forEach(([key, value]) => {
        if (value) {
          classes.push(key)
        }
      })

      if (props.backgroundImage) {
        styles.backgroundImage = `url(${props.backgroundImage})`
      }

      const hasNoDisabledAction = hasSlot(slots, 'action') && !has(attrs, 'isDisabled') && !has(attrs, 'is-disabled')
      /* eslint-disable operator-linebreak */
      return (
        <div
          {...{
            attrs: {
              'data-testid': `${dataTestid} ${getTestId()}`,
              ...newAttrs,
            },
            ...newData,
          }}
          class={ classes }
          staticClass={ staticClass }
          style={ styles }>
            {
              !props.isSectionSummary && !hasSlot(slots, 'imageReplacement') && props.image && props.imagePosition &&
                <ModularTileImage
                  data-testid={getTestId('image')}
                  image={props.image}
                  imagePosition={props.imagePosition}
                />
            }
            {
              hasSlot(slots, 'imageReplacement') &&
                <div class={`${blockClass}__media`}>
                  {slots().imageReplacement}
                </div>
            }
            {
              hasSlot(slots, 'icon') &&
                <div
                  class={`${blockClass}__icon`}
                  data-testid={getTestId('icon')}
                >
                  {slots().icon}
                </div>
            }
            <div class={`${blockClass}__main`}>
              {
                hasSlot(slots, 'error') &&
                  <div
                    class={`${blockClass}__error`}
                    data-testid={getTestId('error')}
                  >
                    {slots().error}
                  </div>
              }
              <div class={{
                [`${blockClass}__header-row`]: props.headerText,
                [`${blockClass}__header-row--no-header-text`]: !props.headerText,
                [`${blockClass}__section-summary`]: props.isSectionSummary,
                }}>
                  {
                    props.headerText &&
                      <Heading
                        id={newAttrs['aria-labelledby'] || undefined}
                        class={
                          {
                            [`${blockClass}__header`]: true,
                            [`${blockClass}__header--bolded`]: props.disableHeaderStyling,
                            [`${blockClass}__header--link`]: props.showLinkToTitle,
                            'nhsuk-u-margin-top-4': true,
                            'nhsuk-u-margin-bottom-3': true,
                          }
                        }
                        data-testid={getTestId('heading')}
                        level={props.headerLevel}
                      >
                        {props.isSectionSummary && <img
                            alt=""
                            class="nhsuk-u-margin-right-2"
                            src={requireImage('section-complete-checkbox.png')}
                          />
                        }
                        {
                          props.showLinkToTitle && hasNoDisabledAction
                            ? slots().action
                            : props.headerText
                        }
                      </Heading>
                  }
                  {props.isSectionSummary &&
                    <div>
                      <ProgressIndicator
                        class="progress-tile__indicator"
                        progress={props.progress}/>
                      <p class="nhsuk-u-margin-top-3 progress-text">You have completed <b>{props.progress}% </b>
                      of the {props.program} programme, and your progress is saved.</p>
                    </div>
                  }
                  {
                    props.tooltipText &&
                      <Tooltip
                        data-testid={getTestId('tooltip')}
                        message={props.tooltipText}
                      />
                  }
                  {
                    props.hasCloseButton && props.closeAction &&
                      <button
                        class={`${blockClass}__close`}
                        aria-label="Close"
                        data-testid={getTestId('close')}
                        type="button"
                        vOn:click={props.closeAction}
                      >
                        <inline-svg
                          src="icons/icon-x.svg"
                          class={`${blockClass}__close-icon`}
                        />
                      </button>
                  }
              </div>
              {
                hasSlot(slots, 'default') &&
                  <div
                    id={newAttrs['aria-describedby'] || undefined }
                    class={`${blockClass}__content`}
                    data-testid={getTestId('content')}
                  >
                    {slots().default}
                  </div>
              }
              {
                !props.showLinkToTitle && hasSlot(slots, 'action') &&
                  <div
                    class={`${blockClass}__action`}
                    data-testid={getTestId('action')}
                  >
                    {
                      has(attrs, 'isDisabled') || has(attrs, 'is-disabled')
                        ? <BaseButton
                            no-margin
                            is-disabled
                            data-testid={getTestId('button')}
                          >
                            { parent.$t(`${slug}.locked`) }
                          </BaseButton>
                        : slots().action
                    }
                  </div>
              }
            </div>
        </div>
      )
    /* eslint-enable operator-linebreak */
    },
  }
</script>

<style lang="scss">
  $block: modular-tile;
  $padding-sm: 1.4rem;
  $padding-lg: 2.2rem;
  $border-radius: 1rem;
  $icon-circle-diameter: 7.2rem;

  .#{$block} {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    font-size: 1.4rem;
    text-decoration: none;
    border-radius: $border-radius;
    background-color: color(_white);
    box-shadow: box-shadow(default);
    &--is-empty {
      color: color(dark-grey);
      border: 2px dashed color(grey);
      background-color: transparent;
      box-shadow: none;
    }
    &--dark {
      background-color: color(dark-primary);
      .#{$block}__header {
        color: color(primary);
      }
      .#{$block}__content {
        color: color(_white);
      }
    }
    &--icon {
      margin-top: $icon-circle-diameter / 2;
      text-align: center;
    }
    &--centered {
      text-align: center;
    }
    &--align-right {
      text-align: right;
      &::before {
        background: linear-gradient(to left,
        white 10%,
        rgba(255,255,255,0.96) 30%,
        rgba(255,255,255,0) 80%);
      }
    }
    &--has-action {
      .#{$block}__content {
        margin: 0 0 2rem;
      }
    }
    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $icon-circle-diameter;
      height: $icon-circle-diameter;
      margin: -$icon-circle-diameter/2 auto 0;
      border-radius: 50%;
      background-color: color(primary);
      box-shadow: box-shadow(button);
      fill: color(_white);
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
    &__main {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 0 $padding-sm $padding-sm;
      z-index: z-index(over-base);
      @include min-lg {
        padding: 0 $padding-lg $padding-lg;
      }
    }
    &__error {
      margin: 2rem 0 0;
    }
    &__content {
      display: flex;
      flex-grow: 1;
      margin: 0;
    }
    &__header-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      min-height: 5.6rem;
      position: relative;
      &--no-header-text {
        justify-content: flex-end;
        height: 0;
        min-height: 0;
        margin-bottom: $padding-sm;
        @include min-lg {
          margin-bottom: $padding-lg;
        }
      }
    }
    &__header {
      flex-grow: 1;
      font-weight: 600;
      &--bolded {
        font-size: 2.1rem;
        font-weight: bold;
        text-transform: none;
      }
      &--link {
        text-decoration: underline;
        color: color(primary);
      }
    }
    &__section-summary {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
      background-color: color(primary);
      color: color(_white);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 0 -1rem 1.5rem -1rem !important;
      padding: 0 $padding-sm $padding-sm;
      @include min-lg {
        padding: 0 $padding-lg $padding-lg;
        margin: 0 -3rem 2rem -3rem !important;
      }
      h1 {
        display: flex;
        align-items: center;
        img {
          width: 30px;
        }
      }

      .progress-indicator {
        background-color: color(_black);
      }
      .progress-indicator-bar {
        background-color: color(_white);
      }
      .progress-text {
        font-size: 1.9rem;
      }
    }
    &__media {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 12rem;
      max-width: 14rem;
      background-color: color(off-white);
      @include min-md {
        min-width: 16rem;
        max-width: 16rem;
      }
    }
    &__close {
      align-self: flex-start;
      width: 4.8rem;
      height: 4.8rem;
      margin-right: -$padding-sm;
      padding: 1.2rem;
      box-sizing: content-box;
      fill: color(dark-grey);
      @include min-lg {
        margin-right: -$padding-lg;
      }
    }
    &__close-icon {
      display: block;
      width: 2.8rem;
      height: 2.8rem;
      margin: 0 auto;
      svg {
        height: 100%;
      }
    }
    &--background-image {
      background-position: center;
      background-size: contain;
      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: z-index(default);
        border-radius: $border-radius;
        background: linear-gradient(to right,
        white 10%,
        rgba(255,255,255,0.96) 30%,
        rgba(255,255,255,0) 80%);
      }
    }
    .tooltip-button {
      padding: 1.6rem;
      right: -$padding-sm;
      box-sizing: content-box;
      @include min-lg {
        right: -$padding-lg;
      }
    }
    .tooltip-text {
      top: 4.4rem;
    }
  }
</style>
