<template>
  <div>
    <accordion
      v-for="(module, i) in modules"
      :key="i"
      data-testid="learn-accordion"
      :startExpanded="isActiveModule(module.id)"
      :title="module.title"
    >
      <template
        #action
        v-if="isActiveModule(module.id)">
        <button
          class="continue-course"
          :dataTestid="'continue-course'"
          @click="startSection">
          {{ isJourneyNotStarted ? "Start the course" : "Continue the course" }}
          <inline-svg
            class="icon"
            role="presentation"
            src="icons/icon-cheveron-right.svg"
          />
        </button>
      </template>
      <journey-module
        data-testid="learn-module"
        v-bind="{
          module,
          i,
          showContinueButton: isActiveModule(module.id),
          link,
          progressPercent,
          sectionActive: getCurrentSection.id,
        }"
      />
    </accordion>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import Accordion from '@/components/accordion/Accordion'

  import sectionOverview from '@/store/helpers/sectionOverview'

  import JourneyModule from './JourneyModule'

  export default {
    components: {
      JourneyModule,
      Accordion,
    },

    props: {
      progressPercent: {
        type: Number,
        required: true,
      },
    },
    methods: {
      isActiveModule(id) {
        return !this.isJourneyCompleted && this.getCurrentSection.module === id
      },
      startSection() {
        this.$router.push(this.link)
      },
    },
    computed: {
      ...mapGetters('program', [
        'getSectionsByModule',
        'getModules',
        'getCurrentSection',
        'getCurrentSectionItem',
      ]),
      link() {
        if (!this.getCurrentSectionItem) return
        return sectionOverview
          .nextLinkItem(this.getCurrentSection, this.getCurrentSectionItem)
      },
      modules() {
        return this.getModules.filter(({ id }) => {
          if (this.getSectionsByModule(id)) {
            return true
          }
          return false
        })
      },
      isJourneyCompleted() {
        return this.progressPercent === 100
      },
      isJourneyNotStarted() {
        return this.progressPercent === 0
      },
    },
  }
</script>

<style lang="scss">
  .continue-course {
    max-width: fit-content;
    margin-top: 1rem;
    margin-left: 0 !important;
  }
</style>
