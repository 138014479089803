<template>
  <router-link
    tabindex="0"
    class="journey-module-article nhsuk-u-font-size-22"
    :class="{
      'journey-article--disabled': isDisabled,
      'journey-article--is-active': isActive,
    }"
    data-testid="journey-article"
    :aria-disabled="isDisabled"
    :to="articleLink"
  >
    {{ item.display_name || item.title }}
  </router-link>
</template>

<script>
  import { mapGetters } from 'vuex'

  import sectionOverview from '@/store/helpers/sectionOverview'

  export default {
    components: {
    },

    props: {
      item: {
        type: Object,
        required: true,
      },
      sectionId: {
        type: String,
        default: '',
        required: false,
      },
    },
    methods: {

    },
    computed: {
      ...mapGetters('program', [
        'isSectionItemCurrent',
        'getCurrentSectionItem',
        'getCurrentSection',
        'getCurrentSectionItemId',
        'getSection',
      ]),
      isDisabled() {
        return !this.isCompleted && !this.isActive
      },
      articleLink() {
        if (this.isDisabled) {
          return '#'
        }

        if (this.item.id === 'first-article') {
          return {
            name: 'ArticleView',
            params: {
              id: `${sectionOverview.OVERVIEW_KEY}${this.sectionId}`,
              section: 'overview',
            },
          }
        }

        return {
          name: 'ArticleView',
          params: {
            id: this.item.id,
          },
        }
      },
      isActive() {
        if (this.isCompleted) return true

        return sectionOverview.isActive(
          this.getCurrentSection,
          this.getCurrentSectionItemId,
          this.getSection(this.sectionId),
          this.item.id,
        )
      },
      isCompleted() {
        return sectionOverview.isCompleted(
          this.getSection(this.sectionId),
          this.item,
        )
      },
    },
  }
</script>

<style lang="scss">

  .journey-module-article {
    display: inline-flex;
  }

  .sections-module-text li:not(.journey-article--is-completed) {
    list-style-type: disc;
    a.journey-article--disabled {
      background-color: transparent;
      color: color(_black);
      box-shadow: none;
    }
  }

  a.journey-article--is-active {
    text-decoration: underline;
    color: color(primary) !important;
  }

  .journey-article--is-completed {
    >a {
      color: color(_black) !important;
      text-decoration: underline;
      display: flex;
      align-items: center;
    }
    >a::before {
      background-repeat: no-repeat;
      background-size: cover;
      width: 15px;
      min-width: 15px;
      height: 15px;
      content: '';
      background-image: url('~@/assets/img/icon-green-check.png');
      margin-right: 0.5rem;
      margin-left: -16px;
    }
  }
</style>
