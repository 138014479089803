var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-main',_vm._b({staticClass:"article-view",class:( _obj = {}, _obj[("article-view--is-" + _vm.articleType)] = !!_vm.articleType, _obj ),attrs:{"background":""}},'base-main',{ loading: _vm.loading },false),[(_vm.isLoadingJourney)?_c('SpinnerDots',{staticClass:"spinner"}):_c('div',{staticClass:"article-view__wrapper"},[_c('transition-article',[(_vm.sectionId)?_c('journey-section-overview',{key:_vm.sectionId,attrs:{"sectionId":_vm.sectionId}}):_c('base-article',_vm._b({key:_vm.id,attrs:{"is-journey":""},on:{"submit":_vm.onSubmit}},'base-article',{
          article: _vm.articleProgram,
          content: _vm.article,
          id: _vm.id,
          isCompleted: _vm.isCompleted,
        },false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }