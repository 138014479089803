<template>
  <base-main
    background
    class="article-view"
    :class="{
      [`article-view--is-${articleType}`]: !!articleType,
    }"
    v-bind="{ loading }"
  >
  <SpinnerDots
      v-if="isLoadingJourney"
      class="spinner"/>

    <div
    v-else
      class="article-view__wrapper">
      <transition-article>
        <journey-section-overview
          v-if="sectionId"
          :key="sectionId"
          :sectionId="sectionId" />
        <base-article
          v-else
          :key="id"
          is-journey
          v-bind="{
            article: articleProgram,
            content: article,
            id,
            isCompleted,
          }"
          @submit="onSubmit"
        />
      </transition-article>
    </div>
  </base-main>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

  import {
    BaseArticle,
    BaseMain,
  } from '@/components/base'

  import JourneySectionOverview from '@/components/journey/JourneySectionOverview'
  import { SpinnerDots } from '@/components/spinner'

  import sectionOverview from '@/store/helpers/sectionOverview'
  import TransitionArticle from '@/transitions/transition-article/TransitionArticle'

  export default {
    metaInfo() {
      const { title, display_name } = this.articleProgram || {}

      return {
        // eslint-disable-next-line camelcase
        title: title || display_name,
      }
    },

    components: {
      BaseArticle,
      BaseMain,
      TransitionArticle,
      SpinnerDots,
      JourneySectionOverview,
    },

    props: {
      id: {
        type: [String, Number],
        required: true,
      },
      section: {
        type: String,
        required: false,
        default: '',
      },
    },

    watch: {
      id: {
        handler() {
          this.setArticle(null)
          const regex = new RegExp(sectionOverview.OVERVIEW_KEY)
          if (this.id !== this.article?.id) {
            this.article = null
          }
          if (regex.test(this.id)) {
            const result = this.id.split(sectionOverview.OVERVIEW_KEY)
            const [, sectionId] = result
            this.sectionId = sectionId
          } else if (this.id !== -1 && !this.isProfileData) {
            this.sectionId = null
            // We fetch the article content if and only if this
            // article is not profile data. Article access
            // and progress are stored if and only if the article has
            // been successfully loaded from the backend.
            this.fetchArticle(this.id).then(article => {
              this.article = article
                this.saveArticleAccess(this.id).then(() => {
                  if (this.shouldSkipProgress) return
                  this.makeProgress()
                })
              })
          } else {
            if (this.shouldSkipProgress) return
            this.makeProgress()
          }
        },
        immediate: true,
      },
    },

    data() {
      return {
        article: null,
        refreshOverview: false,
        sectionId: '',
      }
    },

    methods: {
      ...mapActions('article', [
        'saveArticleAccess',
        'fetchArticle',
      ]),
      ...mapActions('articles', [
        'changeProgress',
      ]),
      ...mapActions('program', [
        'fetchNextSection',
      ]),
      ...mapMutations('article', [
        'setArticle',
      ]),
      onSubmit() {
        if (this.isCompleted) return

        this.makeProgress()
      },
      makeProgress() {
        this.changeProgress({
            program_id: this.currentProgramId,
            subject_id: this.id,
            done: 1,
            type: this.article.type,
          }).then(() => {
            if (!this.isLastInSection) return

            this.fetchNextSection({
              programId: this.currentProgramId,
              sectionKey: this.getCurrentSection.key,
            })
          })
      },
    },

    computed: {
      ...mapGetters('program', [
        'getArticle',
        'currentProgramId',
        'getCurrentSection',
        'isLastArticleInSection',
        'isArticleCompleted',
        'isArticleCurrent',
      ]),
      ...mapState('program', [
        'program',
      ]),
      ...mapGetters('loading', [
        'getLoadingStatesForActions',
      ]),
      isAvailable() {
        return this.isCompleted || this.isCurrent
      },
      isCompleted() {
        return this.isArticleCompleted(this.id)
      },
      isCurrent() {
        return this.isArticleCurrent(this.id)
      },
      isLastInSection() {
        return this.isLastArticleInSection(this.id)
      },
      isProfileData() {
        return this.articleType === 'profile-data'
      },
      isSurvey() {
        return this.articleType === 'survey'
      },
      isSurveyWithTrackProgress() {
        const { track_progress: trackProgress } = this.article || {}

        return this.isSurvey && trackProgress
      },
      articleType() {
        return this.articleProgram?.type
      },
      shouldSkipProgress() {
        return this.id === -1
          || !this.article
          || this.isSurveyWithTrackProgress
          || !this.isAvailable
          || this.isCompleted
      },
      articleProgram() {
        return this.getArticle(this.id)
      },
      loading() {
        const loadings = [
          'program/fetchProgram',
        ]

        const progressLoadings = [
        ]

        return this.isSurveyWithTrackProgress
          ? loadings
          : [
            ...loadings,
            ...progressLoadings,
          ]
      },
      isLoadingJourney() {
        return this.getLoadingStatesForActions([
          'bootstrap/journey',
        ]) && this.program.journey === undefined
      },
    },
  }
</script>

<style lang="scss">
  .article-view {
    &__wrapper {
      @include view-wrapper(63rem);
    }
  }
</style>
