<template>
  <base-main
    class="auth"
    :class="{
      'auth--has-language-select': hasLanguageSelect,
    }"
    data-testid="auth-wrapper"
    v-bind="{ loading }"
    v-merge-attr:data-testid
  >
    <div class="auth__content">

      <div class="auth__background">
        <img
          alt=""
          class="auth__background-image"
          :src="requireImage('login_bg_2.png')"
        >
      </div>

      <slot name="out-wrapper" />

      <div class="auth__tile-wrapper">
        <div
          v-if="showMaintenance"
          class="nhsuk-warning-callout">
          <h3 class="nhsuk-warning-callout__label">
            <span role="alert">
              <span class="nhsuk-u-visually-hidden">Important: </span>
              Scheduled maintenance
            </span>
          </h3>
          <p>{{ maintenanceMessage }}</p>
        </div>
        <modular-tile>
          <div class="auth__container">
            <div
              class="auth__error"
              data-testid="auth-wrapper-error"
            >
              <slot name="error" />
            </div>

            <div
                :class="{'custom__logo-info': program}"
              >
              <img
                :src="program ? program.logo.url : requireImage('logo-ch.png')"
                class="auth__logo"
                alt="Changing Health logo"
              >

              <h1
                class="auth__title"
                data-testid="auth-wrapper-header"
                :style="program ? { 'color': program.colors.dark_primary, } : {}"
              >
                <slot name="title" />
              </h1>
            </div>
            <h2
              v-if="$slots.subtitle"
              class="auth__subtitle"
              data-testid="auth-wrapper-subheader"
            >
              <slot name="subtitle" />
            </h2>

            <base-form
              v-if="hasForm"
              class="auth__form"
              :hasSubmitButton="false"
              v-slot="{ touched }"
              @submit="onSubmit"
            >
              <slot v-bind="{ touched }" />
              <div class="auth__end">
                <div
                  class="auth__actions"
                  data-testid="auth-wrapper-actions"
                >
                  <slot name="action"/>
                </div>

                <div
                  class="auth__links"
                  data-testid="auth-wrapper-links"
                >
                  <slot name="links"/>
                </div>
              </div>
            </base-form>
            <slot v-else />
          </div>
        </modular-tile>
      </div>

    </div>
  </base-main>
</template>

<script>
  import {
    BaseForm,
    BaseMain,
  } from '@/components/base'
  import ModularTile from '@/components/tiles/modular-tile/ModularTile'

  import mergeAttr from '@/directives/mergeAttr'

  import requireImage from '@/mixins/requireImage'

  export default {
    components: {
      BaseMain,
      BaseForm,
      ModularTile,
    },

    directives: {
      mergeAttr,
    },

    mixins: [
      requireImage,
    ],

    props: {
      hasForm: {
        type: Boolean,
        required: false,
        default: true,
      },
      hasLanguageSelect: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Array,
        default: () => [],
      },
      program: {
        type: Object,
        default: null,
      },
    },

    mounted() {
      this.showMaintenance = process.env.VUE_APP_MAINTENANCE?.toLowerCase() === 'true'
      this.maintenanceMessage = process.env.VUE_APP_MAINTENANCE_MESSAGE
    },

    data() {
      return {
        showMaintenance: false,
        maintenanceMessage: '',
      }
    },

    methods: {
      onSubmit(valid) {
        this.$emit('onSubmit', valid)
      },
    },
  }
</script>

<style lang="scss">
  .auth {
    .nhsuk-warning-callout {
      margin-bottom: 1rem;
      position: relative;
    }
    .custom__logo-info {
      margin: -4.4rem -2.5rem 1rem -2.5rem;
      padding: 0 3.4rem 1rem 3.4rem;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      @include min-lg {
        margin: -5.4rem -3.4rem 1rem -3.4rem;
      }
      img {
        max-width: 30%;
        margin-bottom: 0;
      }
      h1 {
        margin-top: 2rem;
        text-align: left;
      }
    }
    .custom__logo-info + h2.auth__subtitle {
      text-align: start;
    }
    :not(.custom__logo-info) > {
      img {
        display: flex;
      }
    }
    @include min-lg {
      top: 0;
    }
    &--has-language-select {
      .auth__end {
        @include min-sm {
          flex-direction: column;
          align-items: normal;
        }
      }
    }
    &__content {
      width: 100%;
      max-width: map-get($breakpoints, sm);
      min-height: fit-content;
      padding: 1rem;
      z-index: z-index(base);
      @include min-lg {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        max-width: none;
        height: 100%;
        min-height: initial;
        padding: 0;
      }
    }
    &__background {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: hidden;
      @include min-lg {
        width: 65%;
        height: 100%;
        margin-left: auto;
        position: absolute;
        background-color: color(off-white);
      }
      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        background: url('~@/assets/img/logo-ch-hex.png') no-repeat;
        background-size: contain;
      }
      &::before {
        width: 75.1rem;
        height: 66.5rem;
        top: -49rem;
        left: -58rem;
        @include min-lg {
          width: 100%;
          height: 100%;
          padding-top: 104.0462%;
          position: static;
          top: unset;
          left: unset;
          background: none;
        }
      }
      &::after {
        width: 64.5rem;
        height: 57.1rem;
        right: -50rem;
        bottom: -37rem;
        @include min-lg {
          display: none;
        }
      }
    }
    &__background-image {
      display: none;
      @include min-lg {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: cover;
        object-position: left;
      }
    }
    &__end {
      display: flex;
      flex-direction: column;
    }
    &__actions {
      display: flex;
    }
    .modular-tile {
      &__header-row {
        display: none;
      }
      &__main {
        padding-bottom: 0;
      }
      &__content {
        margin-bottom: 0;
      }
    }
    &__tile-wrapper {
      height: min-content;
      @include min-lg {
        min-width: 37rem;
        max-width: 23.5%;
        margin: 0 auto 0 12.01%;
      }
    }
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 60rem;
      padding: 1rem 1.1rem 0.8rem;
    }
    &__error {
      margin-top: 1.4rem;
      @include min-lg {
        margin-top: 2.4rem;
      }
    }
    &__logo {
      width: min-content;
      max-width: 57.3333%;
      margin: 3.1rem auto 2.7rem;
    }
    &__title,
    &__subtitle {
      margin: 0.8rem 0;
      text-align: center;
    }
    &__title {
      font-size: 1.8rem;
      font-weight: 800;
      @include min-lg {
        font-size: 2.2rem;
      }
    }
    &__subtitle {
      margin-bottom: 6px;
      font-size: 1.3rem;
      font-weight: 600;
      @include min-lg {
        font-size: 1.6rem;
      }
    }
    &__links {
      margin-bottom: 1.8rem;
      a {
        text-decoration: underline;
      }
    }
    .input-group,
    .input-group__container,
    &__links {
      font-size: 1.2rem;
      @include min-lg {
        font-size: 1.4rem;
      }
    }
    .input-group__input {
      height: 3.2rem;
      @include min-lg {
        height: 4rem;
      }
    }
  }
</style>
