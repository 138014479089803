<template>
  <div
    class="journey-module"
  >
    <p class="nhsuk-u-font-size-24 nhsuk-u-margin-bottom-2">
      {{ module.description }}
    </p>

    <div
      class="nhsuk-u-margin-top-3"
      v-for="(section, j) in sectionsByModuleId"
      :key="j">

      <h3
        class="sections-module-title nhsuk-u-font-size-32 nhsuk-u-margin-bottom-2"
      >
        {{ `${i + 1}.${j + 1} ` }}
        {{ section.display_name && section.display_name[currentUserLanguage]
          ? section.display_name[currentUserLanguage] : section.display_name }}
        {{ section.optional ? '(Optional)' : '' }}
      </h3>

      <journey-module-section :section="section"/>

      <button
        v-if="showContinueButton && section.id === sectionActive"
        class="continue-course nhsuk-u-margin-bottom-1 nhsuk-u-margin-top-2"
        :dataTestid="'continue-course'"
        :class="{
          'nhsuk-u-margin-top-3': j > 0,
        }"
        @click="startSection">
        {{ isJourneyNotStarted ? "Start the course" : "Continue the course" }}
        <inline-svg
          class="icon"
          role="presentation"
          src="icons/icon-cheveron-right.svg"
        />
      </button>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex'

  import JourneyModuleSection from './JourneyModuleSection'

  export default {
    components: {
      JourneyModuleSection,
    },

    props: {
      module: {
        type: Object,
        required: true,
      },
      showContinueButton: {
        type: Boolean,
        required: true,
      },
      link: {
        type: Object,
        required: true,
      },
      i: {
        type: Number,
        required: true,
      },
      progressPercent: {
        type: Number,
        required: true,
      },
      sectionActive: {
        type: String,
        required: true,
      },
    },
    mounted() {
      this.getSections(this.module.id)
    },
    data() {
      return {
        expandedSection: null,
        sectionsByModuleId: [],
      }
    },
    methods: {
      getSections(moduleId) {
        this.sectionsByModuleId = this.getSectionsByModule(moduleId) || []
      },
      startSection() {
        this.$router.push(this.link)
      },
    },
    computed: {
      ...mapGetters('program', [
        'getSectionsByModule',
      ]),
      ...mapState('languages', [
        'currentUserLanguage',
      ]),
      isJourneyNotStarted() {
        return this.progressPercent === 0
      },
    },
  }
</script>

<style lang="scss">
.journey-module {
  .continue-course {
    margin: 0;
    margin-bottom: 1rem;
    max-width: fit-content;
  }
  .sections-module-title {
    font-weight: 700;
    margin-top: 1rem;
  }

}
</style>
