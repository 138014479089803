<script>
  import { bemConstructor } from '@/helpers'

  import testid from '@/mixins/testid/testid'

  export default {
    mixins: [
      testid(),
    ],

    props: {
      articleTitle: {
        type: String,
        default: '',
      },
      beforeNavigation: {
        type: Function,
        default: () => Promise.resolve(),
      },
      isPrevious: {
        type: Boolean,
        default: false,
      },
      newSectionText: {
        type: String,
        default: '',
      },
      previousSectionText: {
        type: String,
        default: '',
      },
      sectionDescription: {
        type: String,
        default: '',
      },
      sectionTitle: {
        type: String,
        default: '',
      },
    },

    render() {
      const bem = bemConstructor('content-navigation-button')
      const buttonTitle = this.isPrevious
        ? this.sectionTitle
          ? this.previousSectionText || this.$t('Previous section')
          : this.$t('Previous')
        : this.sectionTitle
          ? this.newSectionText || this.$t('Next: new section')
          : this.$t('Next')
      const buttonTestId = `${this.dataTestid}-button`
      const testId = `${buttonTestId} ${buttonTestId}-${this.isPrevious ? 'previous' : 'next'}`
      const ariaLabel = `${
        this.isPrevious
          ? this.sectionTitle
            ? this.previousSectionText || this.$t('Previous section')
            : this.$t('Previous article')
          : this.sectionTitle
            ? this.newSectionText || this.$t('Next: new section')
            : this.$t('Next article')} ${
        this.sectionTitle
      } ${
        this.sectionDescription
      } ${
        this.articleTitle
      }`

      if (!this.sectionTitle && !this.articleTitle) return null

      return (
        /* eslint-disable operator-linebreak */
        <button
          aria-label={ariaLabel}
          class={bem('', [
            this.isPrevious ? 'is-previous' : 'is-next',
            this.sectionTitle ? 'is-section-change' : '',
          ])}
          data-testid={testId}
          onClick={() => this.$emit('click')}
          role="link"
        >
          <span class={bem('title-layout')}>
            <span
              class={bem('title')}
            >
              { buttonTitle }
            </span>
            <span class={bem('arrow')}>
              <inline-svg
                src="icons/icon-cheveron-right.svg"
              />
            </span>
          </span>
          {
            this.sectionTitle &&
              <span class={bem('section-title')}>
                { this.sectionTitle }
              </span>
          }
          {
            this.sectionDescription &&
              <span class={bem('section-description')}>
                { this.sectionDescription }
              </span>
          }
          {
            this.articleTitle &&
              <span class={bem('article-title')}>
                { this.articleTitle }
              </span>
          }
        </button>
        /* eslint-enable operator-linebreak */
      )
    },
  }
</script>

<style lang="scss">
  $block: 'content-navigation-button';

  .#{$block} {
    display: inline-block;
    max-width: 22rem;
    text-align: right;
    & &__title-layout {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 0 0 -0.6rem;
    }
    & &__title {
      font-size: 1.8rem;
      font-weight: 800;
    }
    & &__arrow {
      width: 2.6rem;
      svg {
        width: 4rem;
        height: 4rem;
        fill: color('primary');
      }
    }
    & &__section-title {
      display: block;
      font-size: 1.6rem;
      font-weight: 800;
      margin: 0 0 0.4rem;
    }
    & &__section-description {
      display: block;
      font-size: 1.4rem;
      font-weight: 400;
      margin: 0 0 0.4rem;
    }
    & &__article-title {
      font-size: 1.4rem;
      font-weight: 600;
      text-decoration: underline;
    }
    &--is-previous {
      margin-right: auto;
      text-align: left;
      .#{$block}__title-layout {
        flex-direction: row-reverse;
      }
      .#{$block}__arrow {
        transform: scaleX(-1);
      }
    }
    &--is-next {
      margin-left: auto;
    }
    & a {
      display: block;
    }
  }
</style>
