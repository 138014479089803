import Vue from 'vue'

import underscorize from '@/helpers/underscorize'

import normalizeOptionalData from './helpers/normalizeOptionalData'
import initialState from './state'

const mapOptionalDataById = data => {
  const keys = Object.keys(data)
  const optionalProfileDataById = new Map()
  keys.forEach(key => {
    const { data: items } = data[key]
    items.forEach(item => optionalProfileDataById.set(item.id, item.scheme.display_name))
  })

  return optionalProfileDataById
}

export default {
  setOptionalData(state, data) {
    const values = Object.values(data)
    state.optionalDataMap = mapOptionalDataById(data)

    const {
      optionalData,
      optionalDataKeys,
      optionalDataIdsKeys,
    } = normalizeOptionalData(values)

    state.optionalData = optionalData
    state.optionalDataIdsKeys = optionalDataIdsKeys
    state.optionalDataKeys = optionalDataKeys
  },
  updateOptionalData(state, data) {
    const optionalData = { ...state.optionalData }

    data.forEach(({ category, key, value }) => {
      optionalData[underscorize(category)].items[underscorize(key)].value = value
    })

    state.optionalData = optionalData
  },
  resetState(state) {
    const newState = initialState()
    Object.entries(newState).forEach(([key, value]) => Vue.set(state, key, value))
  },
}
