var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._l((_vm.modules),function(module,i){return _c('accordion',{key:i,attrs:{"data-testid":"learn-accordion","startExpanded":_vm.isActiveModule(module.id),"title":module.title},scopedSlots:_vm._u([(_vm.isActiveModule(module.id))?{key:"action",fn:function(){return [_c('button',{staticClass:"continue-course",attrs:{"dataTestid":'continue-course'},on:{"click":_vm.startSection}},[_vm._v(" "+_vm._s(_vm.isJourneyNotStarted ? "Start the course" : "Continue the course")+" "),_c('inline-svg',{staticClass:"icon",attrs:{"role":"presentation","src":"icons/icon-cheveron-right.svg"}})],1)]},proxy:true}:null],null,true)},[_c('journey-module',_vm._b({attrs:{"data-testid":"learn-module"}},'journey-module',{
        module: module,
        i: i,
        showContinueButton: _vm.isActiveModule(module.id),
        link: _vm.link,
        progressPercent: _vm.progressPercent,
        sectionActive: _vm.getCurrentSection.id,
      },false))],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }